<template>
    <div>
        <div class="row">
            <div class="col-md-4">
                <v-card style="background-color: #fec5bb; border-radius: 30px; border: 2px solid white;">
                    <v-card-title>
                        <i class="menu-icon fas fa-user-friends icon-lg pr-5" style="color: black;"></i>
                        <span class="title font-weight-light text-black"><h4 class="mb-0">Manajemen User</h4></span>
                    </v-card-title>
                </v-card>
            </div>
            <div class="col-md-2 ml-auto">
               <v-btn
                  color="#fec5bb"
                  style="border-radius: 30px; border: 2px solid white; font-size: 13px; height: 50px;"
                  @click="goFormCreate()"
                  >
                  <b>Tambah User</b>
                </v-btn>
            </div>
        </div>
        <v-spacer></v-spacer>
        <div class="row">
            <div class="col-md-12">
                 <v-card>
                    <template>
                    <v-app>
                        <v-data-table
                            :headers="headers"
                            :items="dUser"
                            item-key="Id"
                            :options.sync="options"
                            :server-items-length="tdUser"
                            class="elevation-1"
                            :loading="loadingTable"
                            loading-text="Proses Data..."
                            :footer-props="{
                                'items-per-page-text':'Data Per Halaman',
                                'items-per-page-options': [5, 25, 50, 100]
                            }"
                        >
                        <template v-slot:[`item.number`]="{ index }">
                        <td align="center">{{(options.page * options.itemsPerPage) - options.itemsPerPage + (index + 1)}}</td>
                        </template>
                        <!-- <template v-slot:[`item.selisih`]="{ item }">
                            <span>Masa berlaku sudah</span>
                        </template> -->
                        <template v-slot:[`item.status`]="{ item }">
                          <v-chip
                            color="#a6808c"
                            dark
                          >
                            {{ item.status_fe }}
                          </v-chip>
                        </template>
                         <template v-slot:[`item.keterangan`]="{ item }">
                          <v-chip
                            :color="item.color"
                            dark
                          >
                            {{ item.keterangan }}
                          </v-chip>
                        </template>
                        <template v-slot:[`item.level`]="{ item }">
                          <!-- <v-chip
                            :color="item.color"
                            dark
                          > -->
                            {{ item.level == 0 ? 'Admin' : 'User' }}
                          <!-- </v-chip> -->
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-btn
                                rounded color="#ffa69e"
                                small
                                class="col-md-12 mr-2 mb-2"
                                @click="goFormUpdate(item)"
                            >
                                Edit Data
                            </v-btn>
                            <v-btn
                                rounded color="#5e6472"
                                small
                                class="col-md-12 mr-2 mb-2 text-white"
                                @click="deleteItem(item)"
                            >
                                Hapus Data
                            </v-btn>
                        </template>
                        <template v-slot:no-data>
                        </template>
                        </v-data-table>
                        <v-dialog v-model="dialogDelete" transition="dialog-bottom-transition" max-width="300">
                            <v-card>
                                <v-card-title class="text-h5">Peringatan!</v-card-title>
                                <v-card-text class="text-h6">Yakin hapus Data ?</v-card-text>
                                <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn rounded color="#5e6472" @click="closeDelete">Batal</v-btn>
                                <v-btn rounded color="#ffa69e" dark @click="deleteItemConfirm()">Ok</v-btn>
                                <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-app>
                    </template>
                </v-card>
            </div>
        </div>
    </div>
</template>
<style lang="css">
    .v-application--wrap{ min-height: 0px; }
    .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
        background-color: #ffa69e;
        font-size: 13px;
        vertical-align: inherit;
        margin-top: 5%;
    }
</style>
<script>
import ApiService from "@/core/services/api.service";
import Services from "@/core/services/notasis-api/Services";
import Swal from "sweetalert2";
import storagelocal from "@/core/services/storagelocal.service";
// import VueHtml2pdf from 'vue-html2pdf'

  export default {
    components: {
        // VueHtml2pdf
    },
    props: {
    //   idheader: {
    //       type: String,
    //       required: false
    //   },
    //   accessList: {
    //     type: Array,
    //     required: false,
    //     default: () => []
    //   },
    },
    data(){ 
      return{
        baseUrlUpload: ApiService.getBaseUrlUpload(),
        headers: [
          {
            text: 'Id',
            // align: 'start',
            sortable: false,
            value: 'id',
            align: ' d-none'
          },
          { text: 'NO', value: 'number', align: 'center', sortable: false, class:'black--text text--darken-4' },
          { text: 'NAMA', value: 'name', sortable: false, align: 'center', class:'black--text text--darken-4' },
          { text: 'KATEGORI', value: 'level', sortable: false, align: 'center', class:'black--text text--darken-4' },
          { text: 'JABATAN', value: 'jabatan', class:'black--text text--darken-4', sortable: false, },
          { text: 'EMAIL', value: 'email', class:'black--text text--darken-4', sortable: false, },
          { text: 'NO. HP', value: 'no_hp', class:'black--text text--darken-4', sortable: false, },
          { text: 'AKSI', value: 'actions', sortable: false, width: 5, align: 'center', class:'black--text text--darken-4' },
        ],
        options: {
          page: 1,
          itemsPerPage: 5,
          sortBy: [],
          sortDesc: [],
          groupBy: [],
          groupDesc: [],
          multiSort: true,
          mustSort: false
        },
        dUser: [],
        tdUser: 0,
        loadingTable: false,
        isFirstLoad: false,
        idDelete: '',
        dialogDelete: false,
      }
    }, 
    watch: {
        options: {
            async handler (val) {
                val
                if(!this.isFirstLoad)
                    await this.load2()
            },
            deep: true,
        },
        dialogDelete (val) {
            val || this.closeDelete()
        },
    },
    methods : {
        goFormUpdate(item){
            this.$router.push({name: 'CreateUser', query: { flag: "isUpdate", idData: item.id }}) //data: JSON.stringify(this.dPerseroan)
        },
        goFormCreate(){
            this.$router.push({name: 'CreateUser', query: {flag: "isCreate"}})
        },
        async load2(){
            Promise.all([
                await this.getDataUser(),
            ])
            .then( (results)=> {
            this.isFirstLoad = false
            results
            })
        },
        converterDateTable(date){
            var convDate = new Date(date)
            var dd = String(convDate.getDate()).padStart(2, '0');
            var mm = String(convDate.getMonth() + 1).padStart(2, '0'); 
            var yyyy = convDate.getFullYear();
        
            return dd + '-' + mm + "-" + yyyy
        },
        getDataUser(){
            this.dUser = []
            return new Promise(resolve => {
            var mydata = {
                UID 		        : storagelocal.getLocalStorage('UID'),
                Token		        : storagelocal.getLocalStorage('token'),
                Trigger	            : 'R',
                options             : this.options,
            }
            this.loadingTable = true
            Services.PostData(ApiService, "users", mydata, async response=>{
                var responseCace = response.data
                // for(let i=0; i<responseCace.length; i++){
                //     responseCace[i].tgl_akhir_akte_conv = this.converterDateTable(responseCace[i].tgl_akhir_akte)
                //     var b = Array.from(responseCace[i].selisih.toString());
                //     if(b[0] == "-"){
                //         var a = responseCace[i].selisih.toString()
                //         var c =  a.split("-")
                //         responseCace[i].keterangan = "Masa berlaku sudah lewat " + c[1] + " hari"
                //     }else{
                //         responseCace[i].keterangan = "Masih berlaku"
                //     }
                //     if(responseCace[i].status == '0'){
                //         responseCace[i].status_fe = 'Tidak Aktif'
                //     }else{
                //         responseCace[i].status_fe = 'Aktif'
                //     }
                // }
                this.dUser = responseCace
                this.tdUser = response.total
                resolve(this.dUser);
                this.loadingTable = false
                }, err =>{
                err
                })
            });
        },
        async deleteItem (item){
            this.dialogDelete = true
            this.idDelete = item.id
        },

        async deleteItemConfirm () {
            // MenuUrl: this.$router.currentRoute.path
            var deleteObj = {}
            deleteObj.UID = storagelocal.getLocalStorage('UID'),
            deleteObj.Token = storagelocal.getLocalStorage('token'),
            deleteObj.Trigger = 'D'
            deleteObj.id = this.idDelete
            
            Promise.all([await this.del(deleteObj)])
                .then((resultsdel)=>{
                resultsdel
                this.closeDelete()
                // this.close()
                this.getDataUser()
            })
        },
        del(deleteObj){
          return new Promise(resolve => {
              Services.PostData(ApiService, "users", deleteObj, response=>{
                resolve(response.data);
                if(response.status == 1000){
                  Swal.fire({
                      title: "Berhasil",
                      text: "Sukses Hapus Data",
                      icon: "success",
                      heightAuto: false,
                      timer: 1500
                  });
                }else{
                  Swal.fire({
                      title: "Kesalahan",
                      text: "Gagal Hapus Data",
                      icon: "info",
                      heightAuto: false,
                      timer: 1500
                  });
                }

              }, err =>{
                err
              })
          });
      },
      closeDelete () {
        this.dialogDelete = false
      },
    }
  }
</script>
        